import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ApolloClient, ApolloLink, createHttpLink, DefaultOptions, from, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';

/** HTTP 웹서버 연결 url */
//const httpLink = createHttpLink({uri: "http://192.168.0.26/" });    ///개발
//const httpLink = createHttpLink({uri: "http://localhost:8090" }); ///로컬
const httpLink = createHttpLink({uri: "https://unclewash.net" });  ///운영

/** Apollo Client의 기본속성 */
const defaultOptions: DefaultOptions = {
  //요청한 쿼리를 계속 주시합니다. 해당 쿼리에 변경사항이 생기면 업데이트된 내용을 계속 가져옵니다.
  watchQuery: {
    fetchPolicy: 'no-cache',  ///캐시를 가져오지 않습니다
    errorPolicy: 'ignore',    ///오류를 모두 무시합니다
  },
  //요청한 쿼리의 결과를 처음 한 번만 받아옵니다.
  query: {       
    fetchPolicy: 'no-cache',  ///캐시를 가져오지 않습니다
    errorPolicy: 'all',       ///오류 정보를 모두 가져옵니다
  }
};

/** 모든 HTTP요청을 보낼 때 함께 보낼 headers */
const authMiddleware = new ApolloLink((operation, forward) => {
  const customHeaders = operation.getContext().hasOwnProperty("headers") ? operation.getContext().headers : {};
  console.log(customHeaders);
  operation.setContext({
    headers: {
      bearer: 'ytpXqVYdAxB3qeq54yeU' ///접근 승인 코드
    }
  });
  return forward(operation);
});

/** Apollo Client */
export const _client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([authMiddleware, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions
});

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  registrationStrategy: 'registerWhenStable:30000'
})],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule {}
